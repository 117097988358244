/* User Section -----------------------------------------------------------------------*/ 
.user-section {
    display: flex;
    padding: 15px;
    align-items: center;
    gap: 10px;
    background-color: var(--background-container);
    border-radius: var(--bg-container-br);
    box-shadow: var(--box-shadow-small);
}

.user-profilepicture-small {
    width: 40px;
    height: 40px;
    border-radius: 0.5rem;
    object-fit: cover;
}

.user-profilepicture-medium {
    width: 50px;
    height: 50px;
    border-radius: 0.5rem;
    object-fit: cover;
}

.home-user-section-info {
    display: flex;
    flex-direction: column;
}

.user-section-signinas {
    font-size: 0.6rem;
    color: var(--lighter-text-colour);
}

.user-section-name {
    font-size: 1rem;
    color: var(--neutral-text-colour);
    line-height: 0.9rem;
    font-weight: var(--font-thickness-400d-500l);
}

a {
    text-decoration: none;
}

/* Navigation Section ------------------------------------------------------------------ */
.outer-title {
    color: var(--neutral-text-colour);
    font-size: 1rem;
    font-weight: var(--font-thickness-400d-500l);
    margin-bottom: 5px;
    margin-left: 5px;
}

.nav-section {
    display: flex;
    flex-direction: column;
    padding: 5px 15px;
    background-color: var(--background-container);
    border-radius: var(--bg-container-br);
    box-shadow: var(--box-shadow-small);
}

.nav-section-item {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 15px;
    border-bottom: var(--separator-line);
    cursor: pointer;
}

.nav-section-image {
    width: 20px;
    height: 20px;
    /* background-color: yellow; */
    /* border-radius: 0.6rem; */
    filter: var(--svg-filter);
}

.nav-section-title {
    font-weight: var(--font-thickness-400d-500l);
    font-size: 0.85rem;
    color: var(--neutral-text-colour);
}

.nav-section-title:hover {
    /* font-weight: 600; */
    color: var(--primary-colour);
}

/* Post Containers ----------------------- */
.post-section {
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.home-postcontainer-loading, .userindex-section-userlist, .friendlist-loading {
    background-color: var(--background-container);
    border-radius: var(--bg-container-br);
    padding: 15px;
    color: var(--neutral-text-colour);
    font-size: 0.9rem;
    font-weight: var(--font-thickness-400d-500l);
}

/* Post Item ----------------------------------------------------------------- */
.post-item {
    display: flex;
    flex-direction: column;
    padding: 15px;
    align-items: center;
    gap: 10px;
    background-color: var(--background-container);
    border-radius: var(--bg-container-br);
    box-shadow: var(--box-shadow-small);
}

.post-section-header {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    /* border-bottom: var(--separator-line); */
}

.profilepost-section-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.profilepost-delete {
    background-color: var(--background);
    color: var(--neutral-text-colour);
    padding: 8px 12px;
    border: 1px transparent solid;
    border-radius: 0.4rem;
    font-size: 0.9rem;
}

.profilepost-delete:hover {
    border: 1px solid var(--primary-colour);
}

.post-section-header-info {
    display: flex;
    flex-direction: column;

}

.post-user-name {
    font-size: 0.9rem;
    font-weight: 500;
    color: var(--neutral-text-colour);
}

.post-timestamp {
    font-size: 0.6rem;
    color: var(--lighter-text-colour);
}

.post-section-content {
    color: var(--neutral-text-colour);
    text-align: left;
    width: 100%;
    font-size: 0.8rem;
    /* line-height: 0.8rem; */
    font-weight: var(--font-thickness-300d-400l);
    /* padding-bottom: 10px; */
    /* border-bottom: var(--separator-line); */
}

.post-image {
    width: 100%;
    max-height: 600px;
    object-fit: cover;
}

.post-section-feedback {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: var(--separator-line);
}

.post-section-likes {
    display: flex;
    gap: 2px;
    align-items: center;
}

.comment-item-likesvg {
    width: 15px;
    height: 15px;
    filter: invert(34%) sepia(20%) saturate(4925%) hue-rotate(332deg) brightness(88%) contrast(93%);
    cursor: pointer;
    box-shadow: var(--box-shadow-small);    
}

.post-section-likecount {
    color: var(--neutral-text-colour);
    font-weight: var(--font-thickness-300d-400l);
    font-size: 0.8rem;
    line-height: 1.2rem;
}

.post-section-comments {
    color: var(--neutral-text-colour);
    font-weight: var(--font-thickness-300d-400l);
    font-size: 0.7rem;
}

.post-section-writecomment {
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: var(--separator-line);
}

.post-section-writecomment-end {
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
    /* padding-bottom: 10px;
    border-bottom: var(--separator-line); */
}

.writecomment-section-comment {
    background-color: var(--background);
    color: var(--neutral-text-colour);
    border-radius: 0.3rem;
    border: 1px solid transparent;
    padding: 5px;
    padding-left: 10px;
    font-size: 0.8rem;
    line-height: 1.1rem;
    width: 100%;

}

.writecomment-section-post {
    padding: 7px 12px;
    min-width: fit-content;
    border-radius: 0.3rem;
    border: 1px solid transparent;
    color: var(--neutral-text-colour);
    background-color: var(--background);
    font-size: 0.7rem;
}

.writecomment-section-post:hover {
    border: var(--primary-colour) 1px solid;
    /* color: var(--primary-colour); */
}

.comment-section {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
}

.comment-section-item {
    width: 100%;
    display: flex;
    gap: 10px;
}

.comment-item-right {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.comment-item-author {
    color: var(--neutral-text-colour);
    font-weight: var(--font-thickness-400d-500l);
    font-size: 0.8rem;
    line-height: 0.8rem;
}

.comment-item-content {
    color: var(--neutral-text-colour);
    font-weight: var(--font-thickness-300d-400l);
    font-size: 0.7rem;
    line-height: 0.9rem;
}

.comment-item-right-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.comment-item-right-bot {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 20px;
    /* height: 10px; */
}

.comment-item-timestamp {
    color: var(--lighter-text-colour);
    font-weight: var(--font-thickness-300d-400l);
    font-size: 0.65rem;
}

.comment-item-likes {
    display: flex;
    gap: 2px;
    align-items: center;
}

.comment-section-likecount {
    color: var(--neutral-text-colour);
    font-weight: var(--font-thickness-300d-400l);
    font-size: 0.8rem;
    line-height: 0.9rem;
}

input {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    color: var(--neutral-text-colour);
    font-weight: var(--font-thickness-300d-400l);
}

/* Large Image Component */
.large-image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100% - 80px);
    height: calc(100% - 80px);
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
  }

  .large-image-container {
    position: relative;
    z-index: 99;
    display: flex;
    justify-content: center;
    overflow: hidden;
    max-height: 100%;
    max-width: 100%;
    gap: 10px;
    
  }

  .large-post-info {

    background-color: var(--background-container);
    padding: 20px;
    min-width: 30%;
    max-width: 400px;
    display: flex;
    flex: 1;
    box-sizing: border-box;
    flex-direction: column;
    gap: 10px;
    border-radius: var(--bg-container-br);
  }
  
  .large-post-image {
    border-radius: var(--bg-container-br);
    max-width: 65%;
    max-height: 100%;
    object-fit: contain;
    object-position: center;

  }
  
  .no-scroll {
    overflow: hidden;
  }

  /* Display Likes */
  .display-likes-component {
    width: fit-content;
    position: absolute;
    /* background-color: red; */
    background-color: var(--background-container);
    padding: 6px 12px;
    color: var(--neutral-text-colour);
    font-weight: var(--font-thickness-300d-400);
    font-size: 0.8rem;
    top: 20px;
    white-space: nowrap;
    border: 1px var(--primary-colour) solid;
    border-radius: 0.2rem;
  }

  .display-likes-component-postpage {
    position: absolute;
    /* z-index: 2; */
    /* background-color: red; */
    background-color: var(--background-container);
    padding: 6px 12px;
    color: var(--neutral-text-colour);
    font-weight: var(--font-thickness-300d-400);
    font-size: 0.8rem;
    top: 20px;
    right: 0;
    white-space: nowrap;
    border: 1px var(--primary-colour) solid;
    border-radius: 0.2rem;
  }

  .post-section-feedback, .comment-item-likes {
    position: relative;
  }

  /* .minus {
    z-index: -1;
  } */

  /* Search Bar */
  .friendlist-section-search {
    background-color: var(--background-container);
    color: var(--neutral-text-colour);
    border-radius: var(--bg-container-br);
    padding: 20px;
  }

  .search-section-title {
    color: var(--neutral-text-colour);
    font-weight: var(--font-thickness-400d-500l);
    line-height: 0.9rem;
  }

  .searchbar-container {
    position: relative;
  }
  
  .search-section-searchbar {
    background-color: var(--background);
    padding: 8px;
    border: 0;
    margin-top: 10px;
    border-radius: var(--bg-container-br);
  }

  .search-section-dropdown {
    position: absolute;
    top: 2px;
    background-color: var(--background-container);
    border-radius: var(--bg-container-br);
    /* padding: 5px; */
    min-width: 200px;
    border: 1px solid var(--primary-colour);
    font-size: 0.8rem;
    font-weight: var(--font-thickness-400d-500l);
    display: flex;
    flex-direction: column;
  }

  .search-section-dropdown-row {
    padding: 8px 12px ;
    border-bottom: var(--separator-line);
  }

  .search-section-dropdown-row:hover {
    background-color: var(--background);
    border-radius: var(--bg-container-br);
    padding: 8px 12px ;
    border-bottom: var(--separator-line);
  }

  .search-section-dropdown > :last-child {
    border-bottom: 0;
  }

@media (max-width: 1199px) {
    .user-section {
        padding: 10px;
    }

    .user-profilepicture-small {
        width: 30px;
        height: 30px;
        border-radius: 0.5rem;
    }
    
    .user-profilepicture-medium {
        width: 40px;
        height: 40px;
        border-radius: 0.5rem;
    }

    .nav-section {
        padding: 5px 10px;
    }
    
    .nav-section-item {
        gap: 10px;
        /* padding: 15px 10px; */
    }
    
    .nav-section-image {
        width: 20px;
        height: 20px;
    }

    .post-section {
        gap: 20px;
    }

    .post-item {
        padding: 10px;
    }

    .home-postcontainer-loading, .userindex-section-userlist, .friendlist-loading {
        padding: 10px;
        font-size: 0.8rem;
    }

    .post-user-name {
        font-size: 0.8rem;
    }

    .post-section-content {
        font-size: 0.75rem;
    }

    .comment-item-likesvg {
        width: 12px;
        height: 12px;    
    }
    
    .post-section-likecount {
        font-size: 0.75rem;
        line-height: 1.1rem;
    }
    
    .post-section-comments {
        font-size: 0.65rem;
    }
    
    .writecomment-section-comment {
        font-size: 0.7rem;
    }
    
    .comment-section {
        gap: 10px;
    }
    
    .comment-item-author {
        font-size: 0.8rem;
        line-height: 0.75rem;
    }
    
    .comment-item-content {
        font-size: 0.7rem;
        line-height: 0.8rem;
    }
    
    .comment-section-likecount {
        font-size: 0.75rem;
    }
    
    /* Large Image Component */
    .large-post-info {
      padding: 10px;
    }

    /* Display Likes */
    .display-likes-component, .display-likes-component-postpage {
      top: 15px;
    }

    /* Search Bar */
    .friendlist-section-search {
      padding: 10px;
    }

    .search-section-title {
      font-size: 0.85rem;
    }
    
    .search-section-searchbar {
      margin-top: 5px;
    }

    .search-section-dropdown {
      font-size: 0.75rem;
    }
}

@media (max-width: 799px) {
    /* Create Post Section for Mobile */
    .home-createpost-section, .home-request-section, .home-contacts-section, .shared-settings-section {
        border-radius: 0rem;    
    }
    
    .createpost-title {
        font-size: 0.8rem;
    }
    
    .createpost-text {
        margin-top: 10px;
        border-radius: 0.3rem;
    }
    
    .createpost-button-section {
        padding-top: 5px;
    }

    .mobile-upload-button {
        background-color: var(--background);
        padding: 7px;
        height: 19px;
        border-radius: 0.3rem;
    }

    .upload-svg {
        filter: var(--svg-filter);
        width: 19px;

    }

    /* Post Section for Mobile */
    .post-section {
        gap: 10px;
        margin-bottom: 50px;
    }
    
    .home-postcontainer-loading, .userindex-section-userlist, .friendlist-loading {
        border-radius: 0;
        padding: 10px;
    }
    
    /* Post Item --------------------------------------------- */
    .post-item {
        border-radius: 0;
        padding: 10px 0;
    }
    
    .post-section-header {
        box-sizing: border-box;
        padding: 0px 10px;
    }
    
    .post-user-name {
        font-size: 0.85rem;
        font-weight: var(--font-thickness-400d-500l);
    }
    
    .post-section-content {
        box-sizing: border-box;
        padding: 0px 10px;
    }
    
    .post-image {
        min-width: 100%;
        max-height: 500px;
        object-fit: contain;
    }
    
    .post-section-feedback {
        padding-left: 10px;
        padding-right: 15px;
        box-sizing: border-box;
    }
    
    .post-section-likes {
        display: flex;
        gap: 2px;
        align-items: center;
    }
    
    .comment-item-likesvg {
        width: 15px;
        height: 15px;
        filter: invert(34%) sepia(20%) saturate(4925%) hue-rotate(332deg) brightness(88%) contrast(93%);
        cursor: pointer;
        box-shadow: var(--box-shadow-small);    
    }
    
    .post-section-likecount {
        color: var(--neutral-text-colour);
        font-weight: var(--font-thickness-300d-400l);
        font-size: 0.8rem;
        line-height: 1.2rem;
    }
    
    .post-section-comments {
        font-size: 0.7rem;
    }
    
    .post-section-writecomment {
        box-sizing: border-box;
        padding: 0px 10px;
        padding-bottom: 10px;
    }
    
    .post-section-writecomment-end {
        box-sizing: border-box;
        padding: 0px 10px;
    }
    
    .writecomment-section-comment {
        font-size: 0.8rem;
    }
    .comment-section {
        box-sizing: border-box;
        padding: 0px 10px;
    }
    
    .comment-item-right-bot {
        align-items: flex-start;
        gap: 10px;
    }
    
    .comment-section-likecount {
        font-size: 0.8rem;
        line-height: 0.9rem;
    }

    .settings-switchtheme-button, .settings-logout-button {
        width: 100%;
        color: var(--neutral-text-colour);
        background-color: var(--background-container);
        padding: 10px 15px;
        border: 0;
        border-radius: 0.3rem;
        border: 1px solid var(--primary-colour);
        font-size: 1rem;
    }

    .profilepost-delete {
        padding: 8px 12px;
        border-radius: 0.3rem;
        font-size: 0.8rem;
    }

}

.mobile-header-image {
    width: 100%;
    /* margin-bottom: 10px; */
}

.mobile-settings-overlay {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    /* background-color: red; */
}

.mobile-settings {
    position: absolute;
    right: 10px;
    bottom: 50px;
    background-color: var(--background);
    padding: 5px 15px;
    border-radius: 0.4rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.mobile-settings > .nav-section-title {
    font-size: 12rem;
}

.shared-settings-section {
    display: flex;
    flex-direction: column;
    padding: 5px 15px;
    background-color: var(--background-container);
    border-radius: var(--bg-container-br);
    box-shadow: var(--box-shadow-small);
}

.settings-section-item {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 15px;
    border-bottom: var(--separator-line);
    cursor: pointer;
}

.settings-section-item-last {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 15px;
    /* border-bottom: var(--separator-line); */
    cursor: pointer;
}

.settings-section-svg {
    width: 20px;
    height: 20px;
    filter: var(--svg-filter);
}

.settings-section-title {
    font-weight: var(--font-thickness-400d-500l);
    font-size: 0.85rem;
    color: var(--neutral-text-colour);

}

.nav-section-title:hover {
    color: var(--primary-colour);
}

.last {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 15px;
    /* border-bottom: var(--separator-line); */
    cursor: pointer;
}

.delete-account-overlay {
    position: fixed;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.delete-account-container {
    padding: 40px;
    border-radius: var(--bg-container-br);
    background-color: var(--background);
    border: 1px var(--primary-colour) solid;
}

.delete-account-text {
    color: var(--neutral-text-colour);
    margin-bottom: 20px;
}

.delete-account-buttons {
    display: flex;
    gap: 10px;
}

.delete-account-button {
    border-radius: 0.3rem;
    background-color: var(--background-container);
    color: var(--neutral-text-colour);
    padding: 10px 16px;
    border: 1px solid transparent;
    width: 50%;
    font-size: 0.9rem;
    cursor: pointer;
}

.delete-account-button:hover {
    border: 1px solid var(--primary-colour);
}
