.friends-component {
    display: flex;
    justify-content: center;
    gap: 35px;
    margin-top: 35px;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
    position: relative;
}

.friends-section-one, .friends-section-two {
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.friends-section-one {
    width: 200px;

    position: sticky;
    top: 115px;
    bottom: 35px;
    left: 0;
    height: calc(100dvh - 150px);
    overflow-y: auto;
}

.friends-section-one::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.friends-section-one {
  -ms-overflow-style: none; 
  scrollbar-width: none;  
}

.friends-section-two {
    width: 700px;
}

/* Friend List Section */
.friendlist-section {
    background-color: var(--background-container);
    border-radius: var(--);
    box-shadow: var(--box-shadow-small);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.friendlist-section-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

}

.friendlist-card {
    padding: 15px;
    border: var(--separator-line);
    border-radius: 0.8rem;
}

.friend-pfp {
    width: 100%;
    min-height: 75%;
    object-fit: cover;
    border-radius: 0.5rem;
}

.friendlist-username {
    font-size: 1rem;
    font-weight: var(--font-thickness-400d-500l);
    border-bottom: var(--separator-line);
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.friendlist-bio {
    font-size: 0.8rem;
    font-weight: var(--font-thickness-300d-400l);
    color: var(--neutral-text-colour);
    line-height: 1rem;
}

@media (max-width: 1199px) { 
    .friends-component {
        gap: 20px;
        margin-top: 20px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    
    .friends-section-one, .friends-section-two {
        gap: 20px;
    }
    
    .friends-section-one {
        top: 100px;
        bottom: 20px;
        height: calc(100dvh - 135px);
        width: 30%;
        max-width: 200px;
    }
    
    .friends-section-two {
        width: 70%;
        max-width: 700px;
    }
    
    /* Friend List Section */
    .friendlist-section {
        padding: 10px;
    }
    
    .friendlist-section-grid {
        gap: 10px;
    }
    
    .friendlist-card {
        padding: 10px;
    }
    
    .friend-pfp {
        border-radius: 0.3rem;
    }
    
    .friendlist-username {
        font-size: 0.9rem;
        border-bottom: var(--separator-line);
    }
    
    .friendlist-bio {
        font-size: 0.75rem;
        line-height: 0.9rem;
    }
}

.mobile-friends-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 50px;
}

@media (max-width: 799px) {

    /* Friend List Section */
    .friendlist-section {
        border-radius: 0;
    }
    
    .friendlist-section-grid {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
    
    .friendlist-card {
        padding: 10px;
        border: var(--separator-line);
        border-radius: 0.8rem;
    }
    
    .friend-pfp {
        width: 100%;
        border-radius: 0.5rem;
    }
    
    .friendlist-username {
        font-size: 1rem;
        font-weight: var(--font-thickness-400d-500l);
        border-bottom: var(--separator-line);
        padding-bottom: 5px;
        margin-bottom: 10px;
    }
    
    .friendlist-bio {
        font-size: 0.8rem;
        font-weight: var(--font-thickness-300d-400l);
        color: var(--neutral-text-colour);
        line-height: 1rem;
    }
}
