.user-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
}

.user-page-content {
    display: flex;
    justify-content: center;
    align-self: center;
    gap: 35px;
    margin-top: 35px;
    margin-left: 10px;
    margin-right: 10px;
    /*  */
    position: relative;
}

.user-banner {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: center;
    box-shadow: var(--box-shadow-small);
    /* border-bottom: var(--primary-border); */
}

.user-section-one, .user-section-two {
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.user-section-one {
    width: 400px;

    /*  */
    position: sticky;
    z-index: 9999;
    top: 115px;
    bottom: 35px;
    left: 0;
    height: calc(100dvh - 150px);
    overflow-y: auto;
    /* overflow: hidden; */    
}

/* Hide scrollbar for Chrome, Safari and Opera */
.user-section-one::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.user-section-one {
  -ms-overflow-style: none; 
  scrollbar-width: none;  
}

.user-section-two {
    width: 550px;
}

/* User Page User Section */
.user-user-section {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 15px;
    align-items: center;
    gap: 10px;
    background-color: var(--background-container);
    border-radius: var(--bg-container-br);
    box-shadow: var(--box-shadow-small);
}

.user-section-top {
    display: flex;
    gap: 10px;
    align-items: flex-end;
    width: 100%;
    padding-bottom: 15px;
    border-bottom: var(--separator-line);
}

.userpage-user-section-name {
    font-size: 1.4rem;
    color: var(--neutral-text-colour);
    line-height: 1.1rem;
    font-weight: var(--font-thickness-400d-500l);
    padding-bottom: 10px;
}

.user-section-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    /* gap: 15px; */
}

.user-section-info-bio {
    color: var(--neutral-text-colour);
    font-size: 0.9rem;
    font-weight: var(--font-thickness-300d-400l);
    width: 100%;
}

.user-section-info-item {
    color: var(--neutral-text-colour);
    font-size: 0.8rem;
    line-height: 0.8rem;
    font-weight: var(--font-thickness-300d-400l);
    padding-bottom: 5px;
}

.user-profilepicture-large {
    width: 120px;
    height: 120px;
    border-radius: 0.5rem;
    object-fit: cover;
}
/* User Page Friends Section */
.user-photos-loading, .user-post-loading {
    padding: 15px;
    color: var(--neutral-text-colour);
    font-size: 0.9rem;
    font-weight: var(--font-thickness-400d-500l);
    background-color: var(--background-container);
    border-radius: var(--bg-container-br);
    box-shadow: var(--box-shadow-small);
}

.user-contacts-loading {
    color: var(--neutral-text-colour);
    font-size: 0.9rem;
    font-weight: var(--font-thickness-400d-500l);
    background-color: var(--background-container);
    border-radius: var(--bg-container-br);
    box-shadow: var(--box-shadow-small);
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
}

.user-contacts-section {
    background-color: var(--background-container);
    border-radius: var(--bg-container-br);
    box-shadow: var(--box-shadow-small);
    display: flex;
    flex-direction: column;
    padding: 0px 15px;
}

.user-contacts-section > :last-child {
    border: 0
}

/* User Photos Section */
.user-photos-section {
    background-color: var(--background-container);
    border-radius: var(--bg-container-br);
    box-shadow: var(--box-shadow-small);
    padding: 15px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    grid-gap: 15px;
    /* align-items: start; */
}

.photo-wrapper {
    width: 100%;
    height: 100%;
}

.container-test {
    /* width: 100%;
    height: 100%; */
    position: relative;
    /* z-index: 10; */
    padding-top: 100%;
    box-sizing: border-box;
}

.user-photos-photo {
    cursor: pointer;
    border-radius: 0.4rem;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
}

.container-test .user-photos-photo {
    position: absolute;
    top: 0;
    right:0;
    left: 0;
    bottom: 0;
  }

/* User Request Buttons */
.user-request-buttons {
    position: absolute;
    right: 15px;
    z-index: 1;
    display: flex;
    gap: 10px;
}

.user-request-button {
    padding: 8px 14px;
    /* border: 2px var(--primary-colour) solid; */
    border: transparent 1px solid;
    font-size: 0.8rem;
    font-weight: var(--font-thickness-300d-400l);
    border-radius: 0.3rem;
    background-color: var(--background);
    color: var(--neutral-text-colour);
    cursor: pointer;
}

.user-page-request-container {
    display: flex;
    gap: 10px;
}

@media (max-width: 1199px) {
    
    .user-page-content {
        gap: 20px;
        margin-top: 20px;
        width: 90%;
    }
    
    .user-banner {
        height: 200px;
    }
    
    .user-section-one, .user-section-two {
        gap: 20px;
    }
    
    .user-section-one {
        width: 40%;
        max-width: 400px;
        top: 100px;
        bottom: 20px;
        height: calc(100dvh - 135px); 
    }
    
    .user-section-two {
        width: 60%;
        max-width: 550px;
    }
    
    /* User Page User Section */
    .user-user-section {
        padding: 10px;
    }
    
    .user-section-top {
        padding-bottom: 10px;
    }
    
    .userpage-user-section-name {
        font-size: 1.1rem;
        line-height: 1rem;
        padding-bottom: 5px;
    }
    
    .user-section-info-bio {
        font-size: 0.85rem;
    }
    
    .user-section-info-item {
        font-size: 0.75rem;
        line-height: 0.75rem;
        padding-bottom: 1px;
    }
    
    .user-profilepicture-large {
        width: 80px;
        height: 80px;
        border-radius: 0.5rem;
    }
    /* User Page Friends Section */
    .user-photos-loading, .user-post-loading {
        padding: 10px;
        font-size: 0.8rem;
    }
    
    .user-contacts-loading {
        font-size: 0.8rem;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
    }
    
    .user-contacts-section {
        padding: 0px 10px;
    }
    
    /* User Photos Section */
    .user-photos-section {
        padding: 10px;
        grid-gap: 10px;
    }
    
    .user-photos-photo {
        border-radius: 0.2rem;
    }
    
    /* User Request Buttons */
    .user-request-buttons {
        right: 10px;
    }
    
    .user-request-button {
        padding: 7px 10px;
        font-size: 0.75rem;
        border-radius: 0.2rem;
    }
    
    .user-page-request-container {
        display: flex;
        gap: 10px;
    }

}

@media (max-width: 799px) {
    
    .user-page-content {
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;
        margin-left: 0px;
        margin-right: 0px;
        /* position: relative; */
        width: 100%;
    }
    
    .user-banner {
        height: 200px;
        box-shadow: 0;
    }
    
    .user-section-one, .user-section-two {
        gap: 10px;
        width: 100%;
        max-width: unset;
    }
    
    .user-section-one {
        position:unset ;
        z-index: unset;
        top: unset;
        bottom: unset;
        left: unset;
        height: unset;
        overflow-y: unset;   
    }
    
    /* User Page User Section */
    .user-user-section {
        border-radius: 0;
        box-shadow: 0;
    }
    
    /* User Page Friends Section */
    .user-photos-loading, .user-post-loading {
        padding: 10px;
        border-radius: 0;
        box-shadow: 0;
    }
    
    .user-contacts-loading {
        border-radius: 0;
        box-shadow: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
    }
    
    .user-contacts-section {
        border-radius: 0;
        box-shadow: 0;
    }
    
    /* User Photos Section */
    .user-photos-section {
        border-radius: 0;
        box-shadow: 0;
    }

    .user-section-two {
        margin-bottom: 50px;
    }
}