.home-component {
    display: flex;
    justify-content: center;
    gap: 35px;
    margin-top: 35px;
    margin-bottom: 20px;
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
}

.home-section-one, .home-section-two, .home-section-three {
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.home-section-one {
    width: 200px;
    z-index: 10;
    position: sticky;
    top: 125px;
    bottom: 35px;
    left: 0;
    height: calc(100dvh - 160px);
    overflow-y: auto;
}

.home-section-one::-webkit-scrollbar, .home-section-three::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.home-section-one, .home-section-three {
  -ms-overflow-style: none; 
  scrollbar-width: none;  
}

/* .profile-section-two {
    position: relative;
    width: 550px;
} */

.home-section-three {
    width: 240px;
    /* z-index: -1; */
    /* !!!!!! */
    position: sticky;
    top: 125px;
    bottom: 35px;
    left: 0;
    height: calc(100dvh - 160px);
    overflow-y: auto;
}

.home-section-two {
    width: 550px;    
}

.home-createpost-section, .home-request-section, .home-contacts-section, .shared-settings-section {
    background-color: var(--background-container);
    border-radius: 0.5rem;
    box-shadow: var(--box-shadow-small);

}

/* Create Post Section */

.home-createpost-section {
    display: flex;
    flex-direction: column;
    padding: 15px;
}

.createpost-top-section {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 15px;
    border-bottom: var(--separator-line);
}

.createpost-button-section {
    display: flex;
    width: 100%;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
}

.createpost-title {
    font-size: 0.9rem;
    line-height: 0.9rem;
    font-weight: var(--font-thickness-400d-500l);
    color: var(--neutral-text-colour);
    align-self: center;
}

.createpost-text {
    border: 0;
    outline: 0;
    resize: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    margin-top: 15px;
    width: 100%;
    background-color: var(--background);
    border-radius: 0.4rem;
    padding: 7px;
    box-sizing: border-box;
    color: var(--neutral-text-colour);
    font-weight: var(--font-thickness-300d-400l);
}

.createpost-input-button, .custom-file-upload {
    color: var(--neutral-text-colour);
    background-color: var(--background);
    border: transparent 1px solid;
    font-size: 0.9rem;
    padding: 8px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.3rem;
    white-space: nowrap;
}

.createpost-input-button:hover, .custom-file-upload:hover {
    border: var(--primary-colour) 1px solid;
    
}

input[type="file"] {
    display: none;
}

.createpost-button-section {
    padding-top: 10px;
}

.createpost-file-name, .createpost-file-error {
    color: var(--neutral-text-colour);
    font-size: 0.8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.createpost-file-name {
    color: var(--success-text-colour);
}

.createpost-file-error {
    color: var(--error-text-colour);
}

/* Request Section -------------------------------------- */

.home-request-section {
    display: flex;
    flex-direction: column;
    padding: 0px 15px;
}

.home-request-section > :last-child {
    border: 0
}

.home-request-loading {
    padding: 15px;
    color: var(--neutral-text-colour);
    font-size: 0.9rem;
    font-weight: var(--font-thickness-400d-500l);
}

.request-section-item {
    display: flex;
    flex-direction: column;
    gap: 15px;
    /* padding: 15px; */
    padding: 15px 5px;
    border-bottom: var(--separator-line);
}

.request-item-top {
    display: flex;
    align-items: center;
}

.request-item-top-right {
    display: flex;
    flex-direction: column;
    padding-left: 10px;

}

.request-item-bottom {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.small-user-name {
    font-weight: var(--font-thickness-400d-500l);
    font-size: 0.85rem;
    line-height: 1rem;
}

.small-user-name:hover {
    /* font-weight: 600; */
    color: var(--primary-colour);

}

.request-item-mutuals {
    color: var(--lighter-text-colour);
    font-size: 0.7rem;
}

.request-accept-button, .request-deny-button {
    background-color: var(--background);
    border-radius: 0.4rem;
    padding: 5px 12px;
    border: 1px transparent solid;
    width: 100%;
    color: var(--neutral-text-colour);
    font-size: 0.75rem;
    line-height: 0.8rem;
}

.request-accept-button:hover, .request-deny-button:hover, .user-request-button:hover {
    border: 1px solid var(--primary-colour);
}

/* Contacts Section -------------------------- */
.home-contacts-loading {
    padding: 15px;
    color: var(--neutral-text-colour);
    font-size: 0.9rem;
    font-weight: var(--font-thickness-400d-500l);
}

.home-contacts-section {
    display: flex;
    flex-direction: column;
    padding: 0px 15px;
}

.contact-section-item {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 15px 5px;
    border-bottom: var(--separator-line);
}

.home-contacts-section > :last-child {
    border: 0
}

/* Media Query => 800 - 1199px width */

@media (max-width: 1199px) {

    .home-component {
        gap: 20px;
        margin-top: 20px;
        width: 90%;
        align-self: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }

    .home-section-one, .home-section-two, .home-section-three {
        gap: 20px;
    }

    .home-section-one {
        width: 22%;
        max-width: 200px;
        top: 108px;
        height: calc(100dvh - 128px);
        bottom: 20px;
    }

    .home-section-two {
        width: 50%; 
        max-width: 550px;   
    }

    .home-section-three {
        width: 28%;
        max-width: 240px;
        top: 108px;
        height: calc(100dvh - 128px);
        bottom: 20px;
    }

    .shared-settings-section {
        padding: 5px;
        gap: 0px;
    }

    .home-createpost-section {
        padding: 10px;
    }

    .createpost-top-section {
        padding-bottom: 10px;
    }

    .createpost-text {
        margin-top: 10px;
    }

    .createpost-button-section {
        padding-top: 5px;
    }

    .home-request-loading {
        padding: 10px;
    }

    .home-request-section {
        padding: 0px 10px;
    }

    .request-section-item {
        padding: 10px 5px;
    }

    .home-contacts-loading {
        padding: 10px;
    }
    
    .home-contacts-section {
        padding: 0px 10px;
    }
    
    .contact-section-item {
        gap: 10px;
        padding: 10px 5px;
    }
} 

/* MOBILE LAYOUT ---------------------- */
.mobile-home-container {
    min-width: 320px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
