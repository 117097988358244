/* Profile Page */
.profile-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;

}

.profile-page-content {
    display: flex;
    justify-content: center;
    align-self: center;
    gap: 35px;
    margin-top: 35px;
    margin-left: 10px;
    margin-right: 10px;
}

.profile-banner {
    position: relative;
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: center;
    box-shadow: var(--box-shadow-small);
    /* border-bottom: var(--primary-border); */
}

.profile-section-one, .profile-section-two {
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.profile-section-one {
    width: 400px;
    position: sticky;
    z-index: 9999;
    top: 115px;
    bottom: 35px;
    left: 0;
    height: calc(100dvh - 150px);
    overflow-y: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.profile-section-one::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.profile-section-one {
  -ms-overflow-style: none; 
  scrollbar-width: none;  
}

.profile-section-two {
    width: 550px;
}

.banner-button {
    position: absolute;
    z-index: 1;
    right: 10%;
    bottom: -25px;
    padding: 20px;
    border: 5px solid var(--background-container);
    /* border: 2px var(--primary-colour) solid; */
    font-size: 0.9rem;
    font-weight: var(--font-thickness-300d-400l);
    border-radius: 0.6rem;
    background-color: var(--background);
    color: var(--neutral-text-colour);
    box-shadow: var(--box-shadow-small);
    cursor: pointer;
}

.profile-section-two {
    position: relative;
}

.banner-feedback {
    position: absolute;
    z-index: 20;
    top: -25px;
    left: 5px;
}

.avatar-feedback {
    z-index: 9999;
    position: absolute;
    top: -25px;
    left: 5px;
}

.createpost-file-name {
    color: var(--success-text-colour);
}

.createpost-file-error {
    color: var(--error-text-colour);
}

.profile-edit-textarea {
    border: 0;
    outline: 0;
    resize: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    width: 100%;
    background-color: var(--background);
    border-radius: 0.4rem;
    padding: 5px;
    box-sizing: border-box;
    color: var(--neutral-text-colour);
    font-weight: var(--font-thickness-300d-400l)
}

/* User Page User Section */
.profile-user-section {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 15px;
    align-items: center;
    gap: 10px;
    background-color: var(--background-container);
    border-radius: var(--bg-container-br);
    box-shadow: var(--box-shadow-small);
}


/* Profile Edit Section  - - - - - - - - - */
.profile-edit-section {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 15px;
    gap: 10px;
    background-color: var(--background-container);
    border-radius: var(--bg-container-br);
    box-shadow: var(--box-shadow-small);
}

.profile-edit-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.profile-edit-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile-edit-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.profile-edit-form-item {
    display: flex;
    flex-direction: column;
}

.profile-edit-form-item > label {
    color: var(--neutral-text-colour);
    font-size: 0.6rem;
    margin-left: 4px;
}

.profile-edit-buttons {
    position: absolute;
    right: 15px;
    z-index: 1;
    display: flex;
    gap: 10px;
}

.profile-edit-button {
    padding: 8px 14px;
    /* border: 2px var(--primary-colour) solid; */
    border: 0;
    font-size: 0.8rem;
    font-weight: var(--font-thickness-300d-400l);
    border-radius: 0.3rem;
    background-color: var(--background);
    color: var(--neutral-text-colour);
    cursor: pointer;
    border: 1px solid transparent;

}

.profile-edit-button-cancel {
    padding: 8px 14px;
    /* border: 2px var(--primary-colour) solid; */
    border: 0;
    font-size: 0.8rem;
    font-weight: var(--font-thickness-300d-400l);
    border-radius: 0.3rem;
    background-color: var(--background);
    color: var(--neutral-text-colour);
    cursor: pointer;
    border: 1px solid transparent;

}

.profile-edit-input {
    background-color: var(--background);
    border: 0;
    padding: 5px;
    border-radius: 0.4rem;
}

.profile-edit-title {
    font-size: 1.4rem;
    color: var(--neutral-text-colour);
    line-height: 1.1rem;
    font-weight: var(--font-thickness-400d-500l);
}

.profile-edit-button-confirm {
    padding: 8px 14px;
    /* border: 2px var(--primary-colour) solid; */
    border: 0;
    font-size: 0.8rem;
    font-weight: var(--font-thickness-300d-400l);
    border-radius: 0.3rem;
    background-color: var(--background);
    color: var(--neutral-text-colour);
    cursor: pointer;
    border: 1px solid transparent;
}

.profile-edit-button:hover, .profile-edit-button-cancel:hover, .profile-edit-button-confirm:hover {
    border: 1px solid var(--primary-colour);
}

@media (max-width: 1199px) {
    /* Profile Page */
    .profile-page-content {
        gap: 20px;
        margin-top: 20px;
        width: 90%;
    }

    .profile-banner {
        height: 200px;
    }

    .profile-section-one, .profile-section-two {
        gap: 20px;
    }

    .profile-section-one {
        width: 40%;
        top: 100px;
        bottom: 20px;
        height: calc(100dvh - 135px);
        max-width: 400px;
    }

    .profile-section-two {
        width: 60%;
        max-width: 550px;
    }

    .banner-button {
        bottom: -15px;
        padding: 10px;
        border: 3px solid var(--background-container);
        font-size: 0.8rem;
        border-radius: 0.4rem;
    }

    .banner-feedback {
        top: -20px;
    }

    .createpost-file-name, .createpost-file-error {
        font-size: 0.75rem;
    }

    .profile-edit-textarea {
        border-radius: 0.3rem;
        font-size: 0.8rem;
    }

    /* User Page User Section */
    .profile-user-section {
        padding: 10px;
    }

    /* Profile Edit Section  - - - - - - - - - */
    .profile-edit-section {
        padding: 10px;
    }

    .profile-edit-content {
        gap: 10px;
    }

    .profile-edit-buttons {
        right: 10px;
    }

    .profile-edit-button {
        padding: 7px 10px;
        font-size: 0.75rem;
    }

    .profile-edit-button-cancel {
        padding: 7px 10px;
        font-size: 0.75rem;
    }

    .profile-edit-input {
        font-size: 0.8rem;
        border-radius: 0.2rem;
    }

    .profile-edit-title {
        font-size: 1rem;
    }

    .profile-edit-button:hover, .profile-edit-button-cancel:hover, .profile-edit-button-confirm:hover {
        border: 1px solid var(--primary-colour);
    }
}

@media (max-width: 799px) {

    .profile-page-content {
        box-sizing: border-box;
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 50px;
        width: 100%;
    }
    
    .profile-banner {
        height: 200px;
        box-shadow: 0;
    }

    .profile-section-one {
        position:unset ;
        z-index: unset;
        top: unset;
        bottom: unset;
        left: unset;
        height: unset;
        overflow-y: unset;
    }
    
    .profile-section-one, .profile-section-two {
        gap: 10px;
        width: 100%;
        max-width: unset;
    }
    
    .banner-button {
        bottom: -15px;
        padding: 10px;
        border: 3px solid var(--background-container);
        font-size: 0.8rem;
        border-radius: 0.4rem;
        box-shadow: 0;
    }

    .banner-feedback > .createpost-file-error {
        background-color: var(--background-container);
        padding: 20px;
        border-radius: 0.4rem;
    }

    .profile-section-two {
        position: unset;
    }
    
    .banner-feedback {
        position: absolute;
        z-index: 20;
        left: 20%;
        right: 20%;
        top: 50px;
    }
    
    .profile-edit-textarea {
        border-radius: 0.3rem;
    }
    
    /* User Page User Section */
    .profile-user-section {
        border-radius: 0;
        box-shadow: 0;
    }
    
    
    /* Profile Edit Section  - - - - - - - - - */
    .profile-edit-section {
        border-radius: 0;
        box-shadow: 0;
    }
    
    .profile-edit-buttons {
        right: 10px;
    }
    
    .profile-edit-title {
        font-weight: var(--font-thickness-300d-400l);
    }

}

