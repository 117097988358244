.userindex-component {
    display: flex;
    justify-content: center;
    gap: 35px;
    margin-top: 35px;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
    position: relative;
}

.userindex-section-one, .userindex-section-two {
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.userindex-section-one {
    width: 200px;

    position: sticky;
    top: 115px;
    bottom: 35px;
    left: 0;
    height: calc(100dvh - 150px);
    overflow-y: auto;
}

.userindex-section-one::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.userindex-section-one {
  -ms-overflow-style: none; 
  scrollbar-width: none;  
}

.userindex-section-two {
    width: 700px;
}

.userindex-user-card {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.userindex-requests-in {
    box-sizing: border-box;
    width: 100%;
    background-color: var(--background-container);
    border-radius: var(--bg-container-br);
    color: var(--neutral-text-colour);
    padding: 20px;

}

.userindex-requests-title {
    line-height: 0.9rem;
    font-weight: var(--font-thickness-400d-500l);
    margin-bottom: 10px;
}

.userindex-userlist-title {
    line-height: 0.9rem;
    font-weight: var(--font-thickness-400d-500l);
    /* margin-bottom: 10px; */
    font-size: 1rem;
}

.index-requests-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.userindex-username {
    margin-bottom: 5px;
    font-weight: var(--font-thickness-400d-500l);
    font-size: 0.9rem;
    line-height: 0.9rem;
}

.userindex-item-bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}

.userindex-accept-button, .userindex-deny-button {
    background-color: var(--background);
    border-radius: 0.4rem;
    padding: 5px 12px;
    border: 1px transparent solid;
    width: 48%;
    color: var(--neutral-text-colour);
    font-size: 0.75rem;
    line-height: 0.8rem;
}

.userindex-accept-button:hover, .userindex-deny-button:hover, .usercard-send-request:hover, .usercard-rescind-request:hover {
    border: 1px solid var(--primary-colour);
}

/* User List Section */
.userlist-unadded-users {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.usercard-send-request, .usercard-rescind-request {
    background-color: var(--background);
    border-radius: 0.4rem;
    padding: 5px 12px;
    border: 1px transparent solid;
    width: 100%;
    color: var(--neutral-text-colour);
    font-size: 0.75rem;
    line-height: 0.8rem;
    margin-top: 5px;
}

@media (max-width: 1199px) {

    .userindex-component {
        gap: 20px;
        margin-top: 20px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    
    .userindex-section-one, .userindex-section-two {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    
    .userindex-section-one {
        top: 100px;
        bottom: 20px;
        height: calc(100dvh - 135px);
        width: 30%;
        max-width: 200px;
    }
    
    .userindex-section-two {
        width: 70%;
        max-width: 700px;
    }
    
    .userindex-requests-in {
        padding: 10px;
        font-size: 0.85rem;
    }
    
    .userindex-userlist-title {
        font-size: 0.85rem;
    }
    
    .index-requests-grid {
        gap: 10px;
    }
    
    .userindex-username {
        font-size: 0.8rem;
        line-height: 0.8rem;
    }
    
    .userindex-accept-button, .userindex-deny-button {
        border-radius: 0.3rem;
        font-size: 0.75rem;
        line-height: 0.75rem;
    }
    
    /* User List Section */
    .userlist-unadded-users {
        gap: 10px;
    }
    
    .usercard-send-request, .usercard-rescind-request {
        border-radius: 0.3rem;
        font-size: 0.75rem;
        line-height: 0.75rem;
    }
}

.mobile-userindex-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 50px;
}

@media (max-width: 799px) {
    
    .userindex-requests-in {
        border-radius: 0;
    }

    .userlist-unadded-users {
        margin-top: 10px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }

    .mobile-userindex-container > .user-photos-outer > .user-photos-section {
        grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    }
    
}