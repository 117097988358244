/* * {
  margin: 0;
  padding: 0;
} */

body.light {
  --background: #edeef5;
  --background-container: #ffffff;
  --background-lighter: #dbdbdb;
  --primary-colour: #2e1f1c;
  --box-shadow-small: rgba(0, 0, 0, 0.04) 0px 1px 8px;
  --separator-line: 1px rgb(230, 230, 230) solid;
  --primary-border: 2px rgb(228, 228, 106) solid;
  
  --neutral-text-colour: rgb(37, 37, 37);
  --lighter-text-colour: rgb(58, 58, 58);
  --neutral-text-colour-opposite: #ebebeb;
  --lighter-text-colour-opposite: #a8a8a8;
  /* --svg-filter: invert(78%) sepia(7%) saturate(3707%) hue-rotate(173deg) brightness(101%) contrast(105%); */

  --font-thickness-300d-400l: 400;
  --font-thickness-400d-500l: 500;
  --svg-filter: invert(10%) sepia(8%) saturate(2643%) hue-rotate(322deg) brightness(94%) contrast(90%);
  --success-text-colour: rgb(52, 175, 52);
  --error-text-colour: rgb(134, 0, 0);
}

body.dark {
  /* --background: rgb(17, 17, 17);
  --background-container: #161616; */
  /* --background: rgb(24, 25, 26);
  --background-container: rgb(36, 37, 38); */

  --background: rgb(19, 20, 21);
  --background-container: rgb(28, 29, 30);
  --background-lighter: #222020;
  --primary-colour: #fec600;
  --box-shadow-small: rgba(0, 0, 0, 0.04) 0px 1px 8px;
  --separator-line: 1px rgb(63, 63, 63) solid;
  --primary-border: 2px rgb(236, 236, 167) solid;
  
  --neutral-text-colour: #ebebeb;
  --lighter-text-colour: #a8a8a8;
  --neutral-text-colour-opposite: rgb(37, 37, 37);
  --lighter-text-colour-opposite: rgb(58, 58, 58);
  --font-thickness-300d-400l: 300;
  --font-thickness-400d-500l: 400;
  --svg-filter: invert(65%) sepia(99%) saturate(795%) hue-rotate(1deg) brightness(110%) contrast(104%);
  --success-text-colour: rgb(140, 190, 140);
  --error-text-colour: rgb(192, 68, 68);
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
  --logo-colour: #fec600;
  --bg-container-br: 0.4rem;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}


/* img {
  width: 25px;
  height: 25px;
} */
textarea {
  resize: none;
}

a {
  color: var(--neutral-text-colour);
}

:disabled, .disabled {
  opacity: 0.5;
  cursor: not-allowed;
}



