.navigation-component {
    width: 100%;
    height: 60px;
    /* If updating, update sticky elements in other components. */
    padding: 10px 0;

    /* background-color: var(--background-container); */
    background-color: var(--logo-colour);
    border-bottom: 8px solid var(--background-container);

    box-shadow: rgba(0, 0, 0, 0.04) 0px 1px 8px;
    position: sticky;
    top: 0;
    z-index: 9990;

}

.navigation-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-left-side {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.nav-right-side {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.nav-logo {
    /* width: 270px; */
    height: 60px;
    border-radius: 0.8rem;
    /* border-bottom: 1px solid var(--primary-colour); */
}

.navbar-name {
    font-size: 1.2rem;
    font-weight: var(--font-thickness-400d-500l);
    color: var(--neutral-text-colour);
}



/* MOBILE LAYOUT */
.mobile-nav {
    box-sizing: border-box;
    width: 100%;
    background-color: var(--background-container);
    display: flex;
    justify-content: space-around;
    position: fixed;
    z-index: 999999;
    bottom: 0;
}

.mobile-nav-item {
    /* height: 30px; */
    width: 28px;
    height: 28px;
    padding: 10px;
    filter: var(--svg-filter);
    cursor: pointer;
}

.mobile-nav-link {
    height: 28px;
}
